/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.mt.co.kr/mtview.php?no=2022080910412023799"
  }, "80년만의 폭우 내린 그날 밤..'HTS 먹통' 한국투자증권에 무슨 일 - 머니투데이")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.businesspost.co.kr/BP?command=article_view&num=289521"
  }, "한투증권 전산장애 이유 있었다, 운용비 초대형증권사 중 '꼴찌' - 비즈니스포스트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mini.truefriend.com/public/terms/service/18.pdf"
  }, "온라인 거래 장애발생시 보상기준 및 절차 - 한국투자증권")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sedaily.com/NewsView/1VPNT2JO1X"
  }, "용인이 걷어찬 네이버 제2 데이터센터, 세종시로 - 서울경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://biz.chosun.com/site/data/html_dir/2019/08/01/2019080101493.html"
  }, "네이버 데이터센터 무산된 용인에 무슨일이... 반대委 대표성 의문 제기돼 - 조선비즈")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://sreweekly.com/"
  }, "SRE WEEKLY – scalability, availability, incident response, automation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://podcast.44bits.io/episodes/131"
  }, "44bits: 131: KT 망 장애")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://podcast.44bits.io/episodes/132"
  }, "44bits: 132: KT 망 장애 보고서, 집에 UPS 운영 경험")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
